import {
  Button,
  Collapse,
  Grid,
  MenuItem,
  Select,
  TextField,
  Divider,
  Chip,
  FormControlLabel,
  Checkbox,
  FormControl,
  IconButton,
  Typography,
} from "@mui/material";
import { Icon } from "leaflet";
import React, { useMemo, useState } from "react";
import { MapContainer, Marker, Polyline, TileLayer, useMap, useMapEvents } from "react-leaflet";
import { ThemeProvider } from "styled-components";
import { getAlertTypeNames, getMuiTheme } from "../../../../utils/utils";
import palette, { polylineColors } from "../../../../../config/colorPalette";
import { clone, isEmpty, isNil } from "ramda";
import { ErrorText } from "./styled";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ThresholdsValues from "../ThresholdsValues";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { IS_ONLINE, MAP_TYPES } from "../../../../../config/configmap";
import { styled } from "@mui/material/styles";
import { handleChannels, maxHandler, minHandler } from "./utils";

const EquipmentInfo = ({
  equipment,
  setEquipment,
  equipmentCollection,
  polylineDisplay,
  centroid,
  assetName,
  measures,
  setMeasures,
  measuresComputed,
  pIndexes,
  measuresAlerts,
  setMeasuresAlerts,
  thresholdsValues,
  setThresholdsValues,
  thresholdsRanges,
  setThresholdsRanges,
  objectMeasures,
  zones,
  setZones,
  infoToggle,
  setInfoToggle,
  channelAsignToggle,
  setChannelAsignToggle,
  channelNameAsignToggle,
  setChannelNameAsignToggle,
  channelDataToggle,
  setChannelDataToggle,
  channelPK,
  setChannelPK,
  equipmentInterMeasurement,
  setEquipmentInterMeasurement,
  measureToggle,
  setMeasureToggle,
  channelToggle,
  setChannelToggle,
  zoneToggle,
  setZoneToggle,
  errors,
  setErrors,
  intl,
}) => {
  const theme = useMemo(() => getMuiTheme(), []);
  const [open, setOpen] = useState([]);

  const thresholdType = useState(getAlertTypeNames(intl));

  const handleToggle = (index) => {
    let newOpen = clone(open);
    newOpen[index] = !newOpen[index];
    for (let j = 0; j < newOpen.length; j++) {
      if (newOpen[index] && index !== j) {
        newOpen[j] = false;
      }
    }
    setOpen(newOpen);
  };

  const useStyles = {
    colorSample: {
      width: "20px",
      height: "20px",
      display: "inline-block",
      marginRight: "8px",
      border: "1px solid #ccc",
      borderRadius: "50%",
    },
  };
  const BootstrapButton = styled(Button)({
    marginBottom: "5%",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: palette.primary,
    borderColor: palette.secondary,
    "&:hover": {
      backgroundColor: palette.link,
      borderColor: palette.secondary,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: palette.link,
      borderColor: palette.secondary,
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(134,26,33,.5)",
    },
  });

  const customCentroidIcon = new Icon({
    iconUrl: require("../../../../../images/Uptech-marker-icon.png"),
    iconSize: [50, 50],
    iconAnchor: [25, 50],
  });
  const customIcon = new Icon({
    iconUrl: require("../../../../../images/Uptech-equipment-marker-icon.png"),
    iconSize: [50, 50],
    iconAnchor: [25, 50],
  });

  const customShadowIcon = new Icon({
    iconUrl: require("../../../../../images/Uptech-equipment-marker-icon.png"),
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    shadowUrl: require("../../../../../images/yellow-shadow.png"), // Ajusta la ruta de la sombra según tu estructura de archivos
    shadowSize: [150, 100], // Tamaño de la sombra, ajusta según sea necesario
    shadowAnchor: [74, 75], // Anclaje de la sombra, ajusta según sea necesario
  });

  const [mapType] = useState("regular");

  const AddEquipment = () => {
    // eslint-disable-next-line no-unused-vars
    const map = useMapEvents({
      click: (e) => {
        if (
          equipment.length < equipmentCollection.length &&
          equipmentCollection[0] !== "" &&
          !isEmpty(polylineDisplay)
        ) {
          setInfoToggle((prev) => [...prev, true]);
          setChannelAsignToggle((prev) => [...prev, false]);
          setChannelNameAsignToggle((prev) => [...prev, false]);
          setMeasureToggle((prev) => [...prev, false]);
          setChannelDataToggle((prev) => [...prev, [false]]);
          setChannelPK((prev) => [
            ...prev,
            [Math.round(polylineDisplay[0].points[polylineDisplay[0].points.length - 1].pk * 100) / 100],
          ]);
          setEquipmentInterMeasurement((prev) => [...prev, 0]);
          let newChannelToggle = clone(channelToggle);
          let newZoneToggle = clone(zoneToggle);
          for (let indexEquipment = 0; indexEquipment < Object.keys(measures).length; indexEquipment++) {
            if (isNil(newZoneToggle[indexEquipment]) && isNil(newChannelToggle[indexEquipment])) {
              newChannelToggle.push([]);
              newZoneToggle.push([]);
              for (
                let indexMeasure = 0;
                indexMeasure < measures[`equipment${indexEquipment + 1}`].length;
                indexMeasure++
              ) {
                newChannelToggle[indexEquipment].push([]);
                newChannelToggle[indexEquipment][indexMeasure].push(false);
                newZoneToggle[indexEquipment].push([]);
                newZoneToggle[indexEquipment][indexMeasure].push([]);
                newZoneToggle[indexEquipment][indexMeasure][0].push(false);
              }
            }
          }
          let cloneRange = clone(thresholdsRanges);
          for (let indexEquipment = 0; indexEquipment < Object.keys(thresholdsRanges).length; indexEquipment++) {
            for (
              let indexChannel = 0;
              indexChannel < thresholdsRanges[`equipment${indexEquipment + 1}`].length;
              indexChannel++
            ) {
              for (
                let indexChannelZone = 0;
                indexChannelZone < thresholdsRanges[`equipment${indexEquipment + 1}`][indexChannel].length;
                indexChannelZone++
              ) {
                for (
                  let indexRange = 0;
                  indexRange <
                  thresholdsRanges[`equipment${indexEquipment + 1}`][indexChannel][indexChannelZone].length;
                  indexRange++
                ) {
                  cloneRange[`equipment${indexEquipment + 1}`][indexChannel][indexChannelZone][indexRange][1] =
                    Math.round(polylineDisplay[0].points[polylineDisplay[0].points.length - 1].pk * 100) / 100;
                }
              }
            }
          }
          setThresholdsRanges(cloneRange);
          setChannelToggle(newChannelToggle);
          setZoneToggle(newZoneToggle);
          setEquipment((prevEquipment) => [
            ...prevEquipment,
            {
              equipmentID: ``,
              name: ``,
              model: "",
              manufacturer: ``,
              latitude: e.latlng.lat,
              longitude: e.latlng.lng,
              statusDetails: "OK",
              measureDuration: 0,
              measureFrequency: 0,
              measurementState: false,
              asset: assetName,
              equipmentType: getRemainingEquipmentType(prevEquipment, equipmentCollection),
              ipAddress: "0.0.0.0",
              supportNotificationStatus: false,
              channels: [0],
              channelsNames: [""],
              channelsLength: [
                Math.round(polylineDisplay[0].points[polylineDisplay[0].points.length - 1].pk * 100) / 100,
              ],
              channelsMeasurementTime: [0],
              channelsSpatialResolution: [0],
              channelsSamplingInterval: [0],
            },
          ]);
        }
      },
    });
    return null;
  };

  const getRemainingEquipmentType = (equipment, equipmentCollection) => {
    const usedEquipmentTypes = equipment.map((item) => item.equipmentType);
    const remainingEquipmentType = equipmentCollection.find((type) => !usedEquipmentTypes.includes(type));
    return remainingEquipmentType || equipmentCollection[0];
  };

  const MyComponentSetView = ({ polyline }) => {
    const map = useMap();
    if (!isEmpty(polyline) && !isNil(polyline[0].points) && !isEmpty(polyline[0].points))
      map.fitBounds(polyline[0].points);
    return null;
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Grid Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item style={{ flex: "1", marginRight: "1%" }}>
            {(isEmpty(polylineDisplay) || equipmentCollection[0] === "") && (
              <h3>{intl.formatMessage({ id: "first.polyline.and.equipment.to.equipment" })}</h3>
            )}
            <section
              style={{
                position: "relative",
                width: "100%",
                borderRadius: "4px",
                border: `1px solid ${palette.primary}`,
                margin: "1.5rem 0",
                padding: "1%",
              }}
            >
              <h1 style={{ marginBottom: "1%" }}>Equipments</h1>
              {equipment.length > 0 &&
                equipment.map((e, indexEquipment) => {
                  return (
                    <div>
                      <Button onClick={() => handleToggle(indexEquipment)}>
                        {e.equipmentID} - {e.equipmentType}
                      </Button>
                      <DeleteForeverIcon
                        onClick={() => {
                          const newEquipments = clone(equipment);
                          newEquipments.splice(indexEquipment, 1);
                          setEquipment(newEquipments);
                        }}
                        sx={{ color: palette.primary, cursor: "pointer" }}
                      />
                      <Collapse in={open[indexEquipment]}>
                        <div>
                          <h1
                            onClick={() => {
                              let newInfoToggle = clone(infoToggle);
                              if (newInfoToggle[indexEquipment]) {
                                newInfoToggle[indexEquipment] = false;
                              } else {
                                newInfoToggle[indexEquipment] = true;
                              }
                              setInfoToggle(newInfoToggle);
                            }}
                            style={{ marginBottom: "3%" }}
                          >
                            {intl.formatMessage({ id: "equipment.info" })}
                            {!infoToggle[indexEquipment] ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                          </h1>
                          <Collapse in={infoToggle[indexEquipment]}>
                            <TextField
                              id={"equipment" + indexEquipment + "name"}
                              style={{ margin: "0 0.2rem 0.8rem" }}
                              defaultValue={e.name}
                              onChange={(e) => {
                                const newErrors = { ...errors };
                                if (isEmpty(e.target.value)) {
                                  newErrors[e.target.id] = "not.empty";
                                } else {
                                  delete newErrors[e.target.id];
                                }
                                setErrors(newErrors);

                                const newEquipments = [...equipment];
                                const updatedEquipment = { ...newEquipments[indexEquipment] };
                                updatedEquipment.name = e.target.value;
                                newEquipments[indexEquipment] = updatedEquipment;

                                setEquipment(newEquipments);
                              }}
                              error={errors[equipment + indexEquipment + "name"] ? true : false}
                              variant="outlined"
                              label={intl.formatMessage({ id: "equipment.name" })}
                              required
                            ></TextField>
                            {!isNil(errors["equipment" + indexEquipment + "name"]) && (
                              <ErrorText>
                                {intl.formatMessage({ id: errors["equipment" + indexEquipment + "name"] })}
                              </ErrorText>
                            )}
                            <TextField
                              id={"equipment" + indexEquipment + "manufacturer"}
                              style={{ margin: "0 0.2rem 0.8rem" }}
                              defaultValue={e.manufacturer}
                              onChange={(e) => {
                                const newErrors = { ...errors };
                                if (isEmpty(e.target.value)) {
                                  newErrors[e.target.id] = "not.empty";
                                } else {
                                  delete newErrors[e.target.id];
                                }
                                setErrors(newErrors);

                                const newEquipments = [...equipment];
                                const updatedEquipment = { ...newEquipments[indexEquipment] };
                                updatedEquipment.manufacturer = e.target.value;
                                newEquipments[indexEquipment] = updatedEquipment;

                                setEquipment(newEquipments);
                              }}
                              error={errors[equipment + indexEquipment + "manufacturer"] ? true : false}
                              variant="outlined"
                              label={intl.formatMessage({ id: "equipment.manufacturer" })}
                              required
                            ></TextField>
                            {!isNil(errors["equipment" + indexEquipment + "manufacturer"]) && (
                              <ErrorText>
                                {intl.formatMessage({ id: errors["equipment" + indexEquipment + "manufacturer"] })}
                              </ErrorText>
                            )}
                            <TextField
                              id={"equipment" + indexEquipment + "model"}
                              style={{ margin: "0 0.2rem 0.8rem" }}
                              defaultValue={e.model}
                              onChange={(e) => {
                                const newErrors = { ...errors };
                                if (isEmpty(e.target.value)) {
                                  newErrors[e.target.id] = "not.empty";
                                } else {
                                  delete newErrors[e.target.id];
                                }
                                setErrors(newErrors);

                                const newEquipments = [...equipment];
                                const updatedEquipment = { ...newEquipments[indexEquipment] };
                                updatedEquipment.model = e.target.value;
                                newEquipments[indexEquipment] = updatedEquipment;

                                setEquipment(newEquipments);
                              }}
                              error={errors[equipment + indexEquipment + "model"] ? true : false}
                              variant="outlined"
                              label={intl.formatMessage({ id: "equipment.model" })}
                              required
                            ></TextField>
                            {!isNil(errors["equipment" + indexEquipment + "model"]) && (
                              <ErrorText>
                                {intl.formatMessage({ id: errors["equipment" + indexEquipment + "model"] })}
                              </ErrorText>
                            )}
                            <TextField
                              id={"equipment" + indexEquipment + "equipmentID"}
                              style={{ margin: "0 0.2rem 0.8rem" }}
                              defaultValue={e.equipmentID}
                              onChange={(e) => {
                                const newErrors = { ...errors };
                                if (isEmpty(e.target.value)) {
                                  newErrors[e.target.id] = "not.empty";
                                } else {
                                  delete newErrors[e.target.id];
                                }
                                setErrors(newErrors);

                                const newEquipments = [...equipment];
                                const updatedEquipment = { ...newEquipments[indexEquipment] };
                                updatedEquipment.equipmentID = e.target.value;
                                newEquipments[indexEquipment] = updatedEquipment;

                                setEquipment(newEquipments);
                              }}
                              error={errors[equipment + indexEquipment + "equipmentID"] ? true : false}
                              variant="outlined"
                              label={intl.formatMessage({ id: "equipment.equipmentID" })}
                              required
                            ></TextField>
                            {!isNil(errors["equipment" + indexEquipment + "equipmentID"]) && (
                              <ErrorText>
                                {intl.formatMessage({ id: errors["equipment" + indexEquipment + "equipmentID"] })}
                              </ErrorText>
                            )}
                            <TextField
                              id={"equipment" + indexEquipment + "_IP"}
                              style={{ margin: "0 0.2rem 0.8rem" }}
                              defaultValue={e.ipAddress}
                              onChange={(e) => {
                                const newErrors = { ...errors };
                                const regex =
                                  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
                                const isValidInput = regex.test(e.target.value);
                                if (isEmpty(e.target.value)) {
                                  newErrors[e.target.id] = "not.empty";
                                } else if (!isValidInput) {
                                  newErrors[e.target.id] = "must.ip";
                                } else {
                                  delete newErrors[e.target.id];
                                }
                                setErrors(newErrors);

                                const newEquipments = [...equipment];
                                const updatedEquipment = { ...newEquipments[indexEquipment] };
                                updatedEquipment.ipAddress = e.target.value;
                                newEquipments[indexEquipment] = updatedEquipment;

                                setEquipment(newEquipments);
                              }}
                              error={errors[equipment + indexEquipment + "_IP"] ? true : false}
                              variant="outlined"
                              label={intl.formatMessage({ id: "ip.address" })}
                              required
                            ></TextField>
                            {!isNil(errors["equipment" + indexEquipment + "_IP"]) && (
                              <ErrorText>
                                {intl.formatMessage({ id: errors["equipment" + indexEquipment + "_IP"] })}
                              </ErrorText>
                            )}
                            <TextField
                              id={"equipment" + indexEquipment + "_channels"}
                              style={{ margin: "0 0.2rem 0.8rem" }}
                              defaultValue={e.channels.length}
                              inputProps={{ min: 1 }}
                              onChange={(e) => {
                                const newErrors = { ...errors };
                                if (isEmpty(e.target.value)) {
                                  newErrors[e.target.id] = "not.empty";
                                } else {
                                  delete newErrors[e.target.id];
                                }
                                setErrors(newErrors);

                                const newEquipments = [...equipment];
                                const updatedEquipment = { ...newEquipments[indexEquipment] };
                                updatedEquipment.channels = [];
                                updatedEquipment.channelsNames = [];
                                updatedEquipment.channelsLength = [];
                                updatedEquipment.channelsSpatialResolution = [];
                                updatedEquipment.channelsSamplingInterval = [];
                                updatedEquipment.channelsMeasurementTime = [];
                                let newChannelDataToggle = clone(channelDataToggle);
                                newChannelDataToggle[indexEquipment] = [];
                                let newChannelPk = clone(channelPK);
                                for (let newIndex = 0; newIndex < parseInt(e.target.value); newIndex++) {
                                  newChannelDataToggle[indexEquipment].push(false);
                                  if (isNil(newChannelPk[indexEquipment][newIndex])) {
                                    newChannelPk[indexEquipment].push(
                                      Math.round(
                                        polylineDisplay[0].points[polylineDisplay[0].points.length - 1].pk * 100,
                                      ) / 100,
                                    );
                                  }
                                }
                                let newThresholdValues = clone(thresholdsValues);
                                let newThresholdRanges = clone(thresholdsRanges);
                                let newZones = clone(zones);
                                let newChannelToggle = clone(channelToggle);
                                let newZoneToggle = clone(zoneToggle);
                                for (
                                  let indMed = 0;
                                  indMed < newThresholdValues[`equipment${indexEquipment + 1}`].length;
                                  indMed++
                                ) {
                                  if (isNil(newZones[indexEquipment][indMed])) {
                                    newZones[indexEquipment].push([]);
                                  }
                                  newThresholdValues[`equipment${indexEquipment + 1}`][indMed].push([]);
                                  newThresholdRanges[`equipment${indexEquipment + 1}`][indMed].push([]);
                                  for (let newIndex = 0; newIndex < parseInt(e.target.value); newIndex++) {
                                    updatedEquipment.channels.push(0);
                                    updatedEquipment.channelsNames.push("");
                                    updatedEquipment.channelsLength.push(
                                      Math.round(
                                        polylineDisplay[0].points[polylineDisplay[0].points.length - 1].pk * 100,
                                      ) / 100,
                                    );
                                    updatedEquipment.channelsSpatialResolution.push(0);
                                    updatedEquipment.channelsSamplingInterval.push(0);
                                    updatedEquipment.channelsMeasurementTime.push(0);
                                    if (
                                      isNil(newThresholdValues[`equipment${indexEquipment + 1}`][indMed][newIndex][0])
                                    ) {
                                      newThresholdValues[`equipment${indexEquipment + 1}`][indMed][newIndex].push([
                                        { type: null, value: 0 },
                                      ]);
                                    }
                                    if (
                                      isNil(newThresholdRanges[`equipment${indexEquipment + 1}`][indMed][newIndex][0])
                                    ) {
                                      newThresholdRanges[`equipment${indexEquipment + 1}`][indMed][newIndex].push([
                                        0,
                                        Math.round(
                                          polylineDisplay[0].points[polylineDisplay[0].points.length - 1].pk * 100,
                                        ) / 100,
                                      ]);
                                    }
                                    if (isNil(newZones[indexEquipment][indMed][newIndex])) {
                                      newZones[indexEquipment][indMed].push(1);
                                    }
                                    if (isNil(newChannelToggle[indexEquipment][indMed][newIndex])) {
                                      newChannelToggle[indexEquipment][indMed].push(false);
                                    }
                                    if (isNil(newZoneToggle[indexEquipment][indMed][newIndex])) {
                                      newZoneToggle[indexEquipment][indMed].push([]);
                                      newZoneToggle[indexEquipment][indMed][newIndex].push(false);
                                    }
                                  }
                                }

                                //updatedEquipment.channels = parseInt(e.target.value);
                                newEquipments[indexEquipment] = updatedEquipment;
                                setChannelPK(newChannelPk);
                                setThresholdsValues(newThresholdValues);
                                setThresholdsRanges(newThresholdRanges);
                                setZones(newZones);
                                setEquipment(newEquipments);
                                setChannelToggle(newChannelToggle);
                                setZoneToggle(newZoneToggle);
                                setChannelDataToggle(newChannelDataToggle);
                              }}
                              type="number"
                              error={errors[equipment + indexEquipment + "_channels"] ? true : false}
                              variant="outlined"
                              label={intl.formatMessage({ id: "measure.channels" })}
                              required
                            ></TextField>
                            {!isNil(errors[equipment + indexEquipment + "_channels"]) && (
                              <ErrorText>
                                {intl.formatMessage({ id: errors[equipment + indexEquipment + "_channels"] })}
                              </ErrorText>
                            )}
                            <br></br>
                            <BootstrapButton
                              variant="contained"
                              onClick={() => {
                                let newChannel = clone(channelNameAsignToggle);
                                if (newChannel[indexEquipment]) {
                                  newChannel[indexEquipment] = false;
                                } else {
                                  newChannel[indexEquipment] = true;
                                }
                                setChannelNameAsignToggle(newChannel);
                              }}
                            >
                              {intl.formatMessage({ id: "channel.names" })}
                              {!channelNameAsignToggle[indexEquipment] ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                            </BootstrapButton>
                            <Collapse in={channelNameAsignToggle[indexEquipment]}>
                              {e.channels.map((channel, indChan) => {
                                return (
                                  <div style={{ margin: "2%", display: "flex", alignItems: "center" }}>
                                    <h1
                                      style={{ marginRight: "1%" }}
                                      onClick={() => {
                                        let newChannel = clone(channelDataToggle);
                                        if (newChannel[indexEquipment][indChan]) {
                                          newChannel[indexEquipment][indChan] = false;
                                        } else {
                                          newChannel[indexEquipment][indChan] = true;
                                          for (
                                            let indexChannelData = 0;
                                            indexChannelData < channelDataToggle[indexEquipment].length;
                                            indexChannelData++
                                          ) {
                                            if (indexChannelData !== indChan) {
                                              newChannel[indexEquipment][indexChannelData] = false;
                                            }
                                          }
                                        }
                                        setChannelDataToggle(newChannel);
                                      }}
                                    >
                                      {intl.formatMessage({ id: "channel" })} {indChan + 1}
                                      {!channelDataToggle[indexEquipment][indChan] ? (
                                        <ArrowDropDownIcon />
                                      ) : (
                                        <ArrowDropUpIcon />
                                      )}
                                    </h1>
                                    <Collapse in={channelDataToggle[indexEquipment][indChan]}>
                                      <TextField
                                        id={`channel_name_${indChan}`}
                                        style={{ margin: "0 0.2rem 0.8rem" }}
                                        defaultValue={""}
                                        onChange={(e) => {
                                          const newEquipments = [...equipment];
                                          const updatedEquipment = { ...newEquipments[indexEquipment] };
                                          updatedEquipment.channelsNames[indChan] = e.target.value;
                                          newEquipments[indexEquipment] = updatedEquipment;
                                          setEquipment(newEquipments);
                                        }}
                                        error={errors[`channel_name_${indChan}`] ? true : false}
                                        variant="outlined"
                                        label={intl.formatMessage({ id: "channel.name" })}
                                        required
                                      ></TextField>
                                      <TextField
                                        id={`channel_length_${indChan}`}
                                        style={{ margin: "0 0.2rem 0.8rem" }}
                                        inputProps={{ min: 0 }}
                                        defaultValue={channelPK[indexEquipment][indChan]}
                                        value={channelPK[indexEquipment][indChan]}
                                        disabled={true}
                                        onChange={(e) => {
                                          const newEquipments = [...equipment];
                                          const updatedEquipment = { ...newEquipments[indexEquipment] };
                                          updatedEquipment.channelsLength[indChan] = parseFloat(e.target.value);
                                          newEquipments[indexEquipment] = updatedEquipment;
                                          setEquipment(newEquipments);
                                        }}
                                        error={errors[`channel_length_${indChan}`] ? true : false}
                                        variant="outlined"
                                        label={intl.formatMessage({ id: "channel.length" })}
                                        type="number"
                                        required
                                      ></TextField>
                                      <TextField
                                        id={`channel_spatial_resolution_${indChan}`}
                                        style={{ margin: "0 0.2rem 0.8rem" }}
                                        inputProps={{ min: 0 }}
                                        defaultValue={0}
                                        onChange={(e) => {
                                          const newEquipments = [...equipment];
                                          const updatedEquipment = { ...newEquipments[indexEquipment] };
                                          updatedEquipment.channelsSpatialResolution[indChan] = parseFloat(
                                            e.target.value,
                                          );
                                          newEquipments[indexEquipment] = updatedEquipment;
                                          setEquipment(newEquipments);
                                        }}
                                        error={errors[`channel_spatial_resolution_${indChan}`] ? true : false}
                                        variant="outlined"
                                        label={intl.formatMessage({ id: "channel.spatial.resolution" })}
                                        required
                                        type="number"
                                      ></TextField>
                                      <TextField
                                        id={`channel_sampling_interval_${indChan}`}
                                        style={{ margin: "0 0.2rem 0.8rem" }}
                                        inputProps={{ min: 0 }}
                                        defaultValue={0}
                                        onChange={(e) => {
                                          const newEquipments = [...equipment];
                                          const updatedEquipment = { ...newEquipments[indexEquipment] };
                                          updatedEquipment.channelsSamplingInterval[indChan] = parseFloat(
                                            e.target.value,
                                          );

                                          newEquipments[indexEquipment] = updatedEquipment;
                                          setEquipment(newEquipments);
                                        }}
                                        error={errors[`channel_sampling_interval_${indChan}`] ? true : false}
                                        variant="outlined"
                                        label={intl.formatMessage({ id: "channel.sampling.interval" })}
                                        required
                                        type="number"
                                      ></TextField>
                                      <TextField
                                        id={`channel_measurement_time_${indChan}`}
                                        style={{ margin: "0 0.2rem 0.8rem" }}
                                        defaultValue={0}
                                        inputProps={{ min: 0 }}
                                        onChange={(e) => {
                                          const newEquipments = [...equipment];
                                          const updatedEquipment = { ...newEquipments[indexEquipment] };
                                          updatedEquipment.channelsMeasurementTime[indChan] = parseFloat(
                                            e.target.value,
                                          );
                                          const suma = updatedEquipment.channelsMeasurementTime.reduce(
                                            (acumulador, valorActual) => acumulador + valorActual,
                                            0,
                                          );
                                          updatedEquipment.measureFrequency = suma;
                                          newEquipments[indexEquipment] = updatedEquipment;
                                          setEquipment(newEquipments);

                                          let newInter = clone(equipmentInterMeasurement);
                                          newInter[indexEquipment] = suma;
                                          setEquipmentInterMeasurement(newInter);
                                        }}
                                        error={errors[`channel_measurement_time_${indChan}`] ? true : false}
                                        variant="outlined"
                                        label={intl.formatMessage({ id: "channel.measurement.time" })}
                                        required
                                        type="number"
                                      ></TextField>
                                    </Collapse>
                                  </div>
                                );
                              })}
                            </Collapse>
                            <BootstrapButton
                              variant="contained"
                              onClick={() => {
                                let newChannel = clone(channelAsignToggle);
                                if (newChannel[indexEquipment]) {
                                  newChannel[indexEquipment] = false;
                                } else {
                                  newChannel[indexEquipment] = true;
                                }
                                setChannelAsignToggle(newChannel);
                              }}
                            >
                              {intl.formatMessage({ id: "channels.to.polylines" })}
                              {!channelAsignToggle[indexEquipment] ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                            </BootstrapButton>
                            <Collapse in={channelAsignToggle[indexEquipment]}>
                              {e.channels.map((channel, indChan) => {
                                return (
                                  <div style={{ margin: "2%", display: "inline-block" }}>
                                    {indChan + 1}.
                                    <Select
                                      id={e.equipmentID + "channel" + indChan}
                                      onChange={(e) => {
                                        const newEquipments = [...equipment];
                                        const updatedEquipment = { ...newEquipments[indexEquipment] };
                                        updatedEquipment.channels[indChan] = parseInt(e.target.value);
                                        newEquipments[indexEquipment] = updatedEquipment;
                                        let cloneRange = clone(thresholdsRanges);
                                        for (
                                          let indexEquipment = 0;
                                          indexEquipment < Object.keys(thresholdsRanges).length;
                                          indexEquipment++
                                        ) {
                                          for (
                                            let indexMeasure = 0;
                                            indexMeasure < thresholdsRanges[`equipment${indexEquipment + 1}`].length;
                                            indexMeasure++
                                          ) {
                                            for (
                                              let indexChannel = 0;
                                              indexChannel <
                                              thresholdsRanges[`equipment${indexEquipment + 1}`][indexMeasure].length;
                                              indexChannel++
                                            ) {
                                              if (indexChannel === indChan) {
                                                for (
                                                  let indexZone = 0;
                                                  indexZone <
                                                  thresholdsRanges[`equipment${indexEquipment + 1}`][indexMeasure][
                                                    indexChannel
                                                  ].length;
                                                  indexZone++
                                                ) {
                                                  cloneRange[`equipment${indexEquipment + 1}`][indexMeasure][
                                                    indexChannel
                                                  ][indexZone][1] =
                                                    Math.round(
                                                      polylineDisplay[parseInt(e.target.value)].points[
                                                        polylineDisplay[parseInt(e.target.value)].points.length - 1
                                                      ].pk * 100,
                                                    ) / 100;
                                                }
                                              }
                                            }
                                          }
                                        }
                                        let newChannelPk = clone(channelPK);
                                        newChannelPk[indexEquipment][indChan] =
                                          Math.round(
                                            polylineDisplay[parseInt(e.target.value)].points[
                                              polylineDisplay[parseInt(e.target.value)].points.length - 1
                                            ].pk * 100,
                                          ) / 100;
                                        updatedEquipment.channelsLength[indChan] = parseFloat(
                                          Math.round(
                                            polylineDisplay[parseInt(e.target.value)].points[
                                              polylineDisplay[parseInt(e.target.value)].points.length - 1
                                            ].pk * 100,
                                          ) / 100,
                                        );
                                        setChannelPK(newChannelPk);
                                        setThresholdsRanges(cloneRange);
                                        setEquipment(newEquipments);
                                      }}
                                      defaultValue={channel}
                                    >
                                      {polylineDisplay.map((p, indPoly) => {
                                        return (
                                          <MenuItem
                                            key={e.equipmentID + "channel" + indChan + "_" + indPoly}
                                            value={indPoly}
                                          >
                                            <div
                                              className={useStyles.colorSample}
                                              style={{
                                                backgroundColor: polylineColors[indPoly],
                                                width: "20px",
                                                height: "20px",
                                                display: "inline-block",
                                                marginRight: "8px",
                                                border: "1px solid #ccc",
                                              }}
                                            ></div>
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                );
                              })}
                            </Collapse>
                            {!isNil(errors[equipment + indexEquipment + "_duration"]) && (
                              <ErrorText>
                                {intl.formatMessage({ id: errors[equipment + indexEquipment + "_duration"] })}
                              </ErrorText>
                            )}
                            <TextField
                              id={equipment + indexEquipment + "_frequency"}
                              style={{ margin: "0 0.2rem 0.8rem", width: "50%" }}
                              defaultValue={e.measureFrequency}
                              value={e.measureFrequency}
                              inputProps={{ max: 86400, min: 0 }}
                              onChange={(e) => {
                                const newErrors = { ...errors };
                                if (isEmpty(e.target.value)) {
                                  newErrors[e.target.id] = "not.empty";
                                } else if (parseFloat(e.target.value) < equipmentInterMeasurement[indexEquipment]) {
                                  newErrors[e.target.id] = "not.higher.than.amount.measurement.time.channels";
                                } else if (parseFloat(e.target.value) > 86400) {
                                  newErrors[e.target.id] = "not.higher.than.day";
                                } else {
                                  delete newErrors[e.target.id];
                                }
                                setErrors(newErrors);

                                const newEquipments = [...equipment];
                                const updatedEquipment = { ...newEquipments[indexEquipment] };
                                updatedEquipment.measureFrequency = parseFloat(e.target.value);
                                newEquipments[indexEquipment] = updatedEquipment;

                                setEquipment(newEquipments);
                              }}
                              type="number"
                              error={errors[equipment + indexEquipment + "_frequency"] ? true : false}
                              variant="outlined"
                              label={intl.formatMessage({ id: "measure.frequency" })}
                              required
                            ></TextField>
                            {!isNil(errors[equipment + indexEquipment + "_frequency"]) && (
                              <ErrorText>
                                {intl.formatMessage({ id: errors[equipment + indexEquipment + "_frequency"] })}
                              </ErrorText>
                            )}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id={"checkbox" + indexEquipment}
                                  sx={{
                                    color: palette.primary,
                                    "&.Mui-checked": {
                                      color: palette.primary,
                                    },
                                  }}
                                  defaultChecked={e.measurementState}
                                  onChange={(e) => {
                                    const newErrors = { ...errors };
                                    if (isEmpty(e.target.value)) {
                                      newErrors[e.target.id] = "not.empty";
                                    } else {
                                      delete newErrors[e.target.id];
                                    }
                                    setErrors(newErrors);

                                    const newEquipments = [...equipment];
                                    const updatedEquipment = { ...newEquipments[indexEquipment] };
                                    updatedEquipment.measurementState = e.target.checked;
                                    newEquipments[indexEquipment] = updatedEquipment;

                                    setEquipment(newEquipments);
                                  }}
                                />
                              }
                              label={intl.formatMessage({ id: "measurement.state" })}
                            />
                          </Collapse>
                        </div>
                        <h1
                          onClick={() => {
                            let newMeasureToggle = clone(measureToggle);
                            if (newMeasureToggle[indexEquipment]) {
                              newMeasureToggle[indexEquipment] = false;
                            } else {
                              newMeasureToggle[indexEquipment] = true;
                            }
                            setMeasureToggle(newMeasureToggle);
                          }}
                          style={{ marginBottom: "3%" }}
                        >
                          {intl.formatMessage({ id: "measure.information" })}
                          {!measureToggle[indexEquipment] ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                        </h1>
                        {measures[`equipment${indexEquipment + 1}`].map((measure, indexMeasure) => {
                          return (
                            <Collapse in={measureToggle[indexEquipment]}>
                              <section
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  borderRadius: "4px",
                                  border: `1px solid ${palette.primary}`,
                                  margin: "1.5rem 0",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-17px",
                                    marginInlineStart: "10px",
                                  }}
                                >
                                  <Chip
                                    label={`Measure ${indexMeasure + 1}`}
                                    variant="outlined"
                                    style={{
                                      backgroundColor: palette.primary,
                                      color: palette.likeWhite,
                                      borderColor: palette.primary,
                                    }}
                                    sx={{ "&.MuiChip-deleteIcon": { color: palette.likeWhite } }}
                                    onDelete={() => {
                                      if (
                                        measure !== "temperature" &&
                                        measure !== "strain" &&
                                        measure !== "vibrations"
                                      ) {
                                        const localMeasures = clone(measures);
                                        const localMeasuresAlerts = clone(measuresAlerts);
                                        const localThresholdsValues = clone(thresholdsValues);
                                        localMeasures[`equipment${indexEquipment + 1}`].splice(indexMeasure, 1);
                                        localMeasuresAlerts[`equipment${indexEquipment + 1}`].splice(indexMeasure, 1);
                                        localThresholdsValues[`equipment${indexEquipment + 1}`].splice(indexMeasure, 1);
                                        setMeasures(localMeasures);
                                        setMeasuresAlerts(localMeasuresAlerts);
                                        setThresholdsValues(localThresholdsValues);
                                      }
                                    }}
                                  />
                                </div>
                                <div style={{ padding: "1rem" }}>
                                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                    <Grid item xs={12} sm={2} style={{ margin: "1rem" }}>
                                      <FormControl>
                                        <TextField
                                          id={"measure" + indexEquipment}
                                          variant="outlined"
                                          style={{ margin: "0 0.2rem", width: "15rem" }}
                                          label={intl.formatMessage({ id: "measure.name" })}
                                          value={measure}
                                          select={
                                            measure === "temperature" ||
                                            measure === "strain" ||
                                            measure === "vibrations"
                                              ? false
                                              : true
                                          }
                                          disabled={
                                            measure === "temperature" ||
                                            measure === "strain" ||
                                            measure === "vibrations"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            const localMeasures = clone(measures);
                                            if (isNil(localMeasures[`equipment${indexEquipment + 1}`][indexMeasure])) {
                                              localMeasures[`equipment${indexEquipment + 1}`][indexMeasure].push(
                                                e.target.value,
                                              );
                                            } else {
                                              localMeasures[`equipment${indexEquipment + 1}`][indexMeasure] =
                                                e.target.value;
                                            }
                                            setMeasures(localMeasures);
                                          }}
                                          error={errors["measure" + indexEquipment] ? true : false}
                                          required
                                        >
                                          {(measure !== "temperature" ||
                                            measure !== "strain" ||
                                            measure !== "vibrations") &&
                                            measuresComputed[`equipment${indexEquipment + 1}`].map(
                                              (computing, index2) => (
                                                <MenuItem
                                                  key={`equipment${indexEquipment + 1}_${index2}`}
                                                  value={computing}
                                                  disabled={measures[`equipment${indexEquipment + 1}`].includes(
                                                    computing,
                                                  )}
                                                >
                                                  {computing}
                                                </MenuItem>
                                              ),
                                            )}
                                        </TextField>
                                        {!isNil(errors["measure" + indexEquipment]) && (
                                          <ErrorText>
                                            {intl.formatMessage({ id: errors["measure" + indexEquipment] })}
                                          </ErrorText>
                                        )}
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                  <Grid>
                                    {!isNil(measuresAlerts[`equipment${indexEquipment + 1}`][indexMeasure]) && (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            id={"checkbox" + indexMeasure}
                                            sx={{
                                              color: palette.primary,
                                              "&.Mui-checked": {
                                                color: palette.primary,
                                              },
                                            }}
                                            defaultChecked={
                                              measuresAlerts[`equipment${indexEquipment + 1}`][indexMeasure] === 1
                                                ? true
                                                : false
                                            }
                                            onChange={(e) => {
                                              let newMA = clone(measuresAlerts);
                                              if (e.target.checked) {
                                                newMA[`equipment${indexEquipment + 1}`][indexMeasure] = 1;
                                              } else {
                                                newMA[`equipment${indexEquipment + 1}`][indexMeasure] = 0;
                                              }
                                              setMeasuresAlerts(newMA);
                                            }}
                                          />
                                        }
                                        label={intl.formatMessage({ id: "send.alert" })}
                                      />
                                    )}

                                    {measure === "temperature" || measure === "strain" || measure === "vibrations" ? (
                                      <>
                                        <Divider
                                          orientation="horizontal"
                                          flexItem
                                          style={{ margin: "0 2rem", backgroundColor: palette.primary }}
                                        />
                                        {e.channels.map((channel, channelIndex) => (
                                          <>
                                            <h1
                                              style={{ margin: "2%" }}
                                              onClick={() => {
                                                let newChannelToggle = clone(channelToggle);
                                                if (newChannelToggle[indexEquipment][indexMeasure][channelIndex]) {
                                                  newChannelToggle[indexEquipment][indexMeasure][channelIndex] = false;
                                                } else {
                                                  newChannelToggle[indexEquipment][indexMeasure][channelIndex] = true;
                                                }
                                                setChannelToggle(newChannelToggle);
                                              }}
                                            >
                                              {intl.formatMessage({ id: "channel.mayus" })} {channelIndex + 1}
                                              {!channelToggle[indexEquipment][indexMeasure][channelIndex] ? (
                                                <ArrowDropDownIcon />
                                              ) : (
                                                <ArrowDropUpIcon />
                                              )}
                                            </h1>
                                            <Collapse in={channelToggle[indexEquipment][indexMeasure][channelIndex]}>
                                              <TextField
                                                id={"equipment" + indexEquipment + "_zones" + equipment}
                                                style={{ margin: "0 0.2rem 0.8rem" }}
                                                defaultValue={zones[indexEquipment][indexMeasure][channelIndex]}
                                                inputProps={{ min: 1 }}
                                                onChange={(e) => {
                                                  handleChannels({
                                                    e,
                                                    thresholdsValues,
                                                    channelIndex,
                                                    errors,
                                                    indexEquipment,
                                                    indexMeasure,
                                                    setErrors,
                                                    setThresholdsRanges,
                                                    setThresholdsValues,
                                                    setZones,
                                                    thresholdsRanges,
                                                    zones,
                                                    zoneToggle,
                                                  });
                                                }}
                                                type="number"
                                                error={
                                                  errors["equipment" + indexEquipment + "_zones" + equipment]
                                                    ? true
                                                    : false
                                                }
                                                variant="outlined"
                                                label={intl.formatMessage({ id: "measure.zones" })}
                                                required
                                              ></TextField>
                                              {Array.from({
                                                length: zones[indexEquipment][indexMeasure][channelIndex],
                                              }).map((_, zoneIndex) => (
                                                <>
                                                  <h4
                                                    style={{ margin: "2%", marginBottom: "5%" }}
                                                    onClick={() => {
                                                      let newZoneToggle = clone(zoneToggle);
                                                      if (
                                                        newZoneToggle[indexEquipment][indexMeasure][channelIndex][
                                                          zoneIndex
                                                        ]
                                                      ) {
                                                        newZoneToggle[indexEquipment][indexMeasure][channelIndex][
                                                          zoneIndex
                                                        ] = false;
                                                      } else {
                                                        newZoneToggle[indexEquipment][indexMeasure][channelIndex][
                                                          zoneIndex
                                                        ] = true;
                                                      }
                                                      setZoneToggle(newZoneToggle);
                                                    }}
                                                  >
                                                    {intl.formatMessage({ id: "zone.mayus" })} {zoneIndex + 1}
                                                    {!zoneToggle[indexEquipment][indexMeasure][channelIndex][
                                                      zoneIndex
                                                    ] ? (
                                                      <ArrowDropDownIcon />
                                                    ) : (
                                                      <ArrowDropUpIcon />
                                                    )}
                                                  </h4>
                                                  <Collapse
                                                    in={
                                                      zoneToggle[indexEquipment][indexMeasure][channelIndex][zoneIndex]
                                                    }
                                                  >
                                                    <Grid container spacing={1}>
                                                      <Grid item xs={3}>
                                                        <TextField
                                                          inputProps={{ min: 0, step: "0.1" }}
                                                          label={intl.formatMessage({ id: "range.min" })}
                                                          type="number"
                                                          variant="outlined"
                                                          fullWidth
                                                          key={`equipment${
                                                            indexEquipment + 1
                                                          }]_${indexMeasure}_${channelIndex}_${zoneIndex}_0`}
                                                          error={
                                                            errors[
                                                              `equipment${
                                                                indexEquipment + 1
                                                              }]_${indexMeasure}_${channelIndex}_${zoneIndex}_0`
                                                            ]
                                                              ? true
                                                              : false
                                                          }
                                                          defaultValue={
                                                            thresholdsRanges[`equipment${indexEquipment + 1}`][
                                                              indexMeasure
                                                            ][channelIndex][zoneIndex][0]
                                                          }
                                                          onChange={(e) => {
                                                            minHandler({
                                                              e,
                                                              thresholdsRanges,
                                                              setThresholdsRanges,
                                                              indexEquipment,
                                                              indexMeasure,
                                                              channelIndex,
                                                              zoneIndex,
                                                              errors,
                                                              setErrors,
                                                            });
                                                          }}
                                                        />
                                                        {!isNil(
                                                          errors[
                                                            `equipment${
                                                              indexEquipment + 1
                                                            }]_${indexMeasure}_${channelIndex}_${zoneIndex}_0`
                                                          ],
                                                        ) && (
                                                          <ErrorText style={{ margin: "0.2rem" }}>
                                                            {intl.formatMessage({
                                                              id: errors[
                                                                `equipment${
                                                                  indexEquipment + 1
                                                                }]_${indexMeasure}_${channelIndex}_${zoneIndex}_0`
                                                              ],
                                                            })}
                                                          </ErrorText>
                                                        )}
                                                      </Grid>
                                                      <Grid item xs={1} style={{ textAlign: "center" }}>
                                                        <Typography variant="h6">-</Typography>
                                                      </Grid>
                                                      <Grid item xs={3}>
                                                        <TextField
                                                          inputProps={{ min: 0, step: "0.1" }}
                                                          key={`equipment${
                                                            indexEquipment + 1
                                                          }]_${indexMeasure}_${channelIndex}_${zoneIndex}_1`}
                                                          label={intl.formatMessage({ id: "range.max" })}
                                                          type="number"
                                                          variant="outlined"
                                                          fullWidth
                                                          defaultValue={
                                                            thresholdsRanges[`equipment${indexEquipment + 1}`][
                                                              indexMeasure
                                                            ][channelIndex][zoneIndex][1]
                                                          }
                                                          value={
                                                            thresholdsRanges[`equipment${indexEquipment + 1}`][
                                                              indexMeasure
                                                            ][channelIndex][zoneIndex][1]
                                                          }
                                                          error={
                                                            errors[
                                                              `equipment${
                                                                indexEquipment + 1
                                                              }]_${indexMeasure}_${channelIndex}_${zoneIndex}_1`
                                                            ]
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) => {
                                                            maxHandler({
                                                              e,
                                                              thresholdsRanges,
                                                              setThresholdsRanges,
                                                              indexEquipment,
                                                              indexMeasure,
                                                              channelIndex,
                                                              zoneIndex,
                                                              polylineDisplay,
                                                              equipment: equipment[indexEquipment],
                                                              errors,
                                                              setErrors,
                                                            });
                                                          }}
                                                        />
                                                        {!isNil(
                                                          errors[
                                                            `equipment${
                                                              indexEquipment + 1
                                                            }]_${indexMeasure}_${channelIndex}_${zoneIndex}_1`
                                                          ],
                                                        ) && (
                                                          <ErrorText style={{ margin: "0.2rem" }}>
                                                            {intl.formatMessage({
                                                              id: errors[
                                                                `equipment${
                                                                  indexEquipment + 1
                                                                }]_${indexMeasure}_${channelIndex}_${zoneIndex}_1`
                                                              ],
                                                            })}
                                                          </ErrorText>
                                                        )}
                                                      </Grid>
                                                    </Grid>
                                                    <ThresholdsValues
                                                      key={`equipment${indexEquipment}_${indexMeasure}_${channelIndex}_${zoneIndex}`}
                                                      thresholdTypes={thresholdType?.[0]}
                                                      errors={errors}
                                                      setErrors={setErrors}
                                                      measureIndex={indexMeasure}
                                                      equipment={`equipment${indexEquipment + 1}`}
                                                      thresholdsValues={thresholdsValues}
                                                      setThresholdsValues={setThresholdsValues}
                                                      thresholdsRanges={thresholdsRanges}
                                                      setThresholdsRanges={setThresholdsRanges}
                                                      equipmentIndex={indexEquipment}
                                                      channelIndex={channelIndex}
                                                      zoneIndex={zoneIndex}
                                                    />
                                                  </Collapse>
                                                </>
                                              ))}
                                            </Collapse>
                                          </>
                                        ))}
                                      </>
                                    ) : null}
                                  </Grid>
                                </div>
                              </section>
                            </Collapse>
                          );
                        })}

                        {!measuresComputed[`equipment${indexEquipment + 1}`].every((elemento) =>
                          measures[`equipment${indexEquipment + 1}`].includes(elemento),
                        ) &&
                        measures[`equipment${indexEquipment + 1}`].length <
                          objectMeasures.find((obj) => obj.name === e.equipmentType).measures.length +
                            objectMeasures.find((obj) => obj.name === e.equipmentType).computing.length ? (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <IconButton
                              style={{ backgroundColor: palette.dividerLight }}
                              key="add_magnitude"
                              onClick={() => {
                                //setTuples([...tuples, { type: null, value: null }]);
                                const localMeasures = clone(measures);
                                const localMeasuresAlerts = clone(measuresAlerts);
                                const localThresholdsValues = clone(thresholdsValues);
                                localMeasures[`equipment${indexEquipment + 1}`].push("");
                                localMeasuresAlerts[`equipment${indexEquipment + 1}`].push(0);
                                localThresholdsValues[`equipment${indexEquipment + 1}`].push([
                                  [[{ type: null, value: 0.5 }]],
                                ]);
                                setMeasures(localMeasures);
                                setMeasuresAlerts(localMeasuresAlerts);
                                setThresholdsValues(localThresholdsValues);
                              }}
                            >
                              <AddIcon sx={{ color: palette.primary }} />
                            </IconButton>
                            <div
                              style={{
                                width: "100%",
                                height: "1px",
                                backgroundColor: palette.primary,
                                margin: "8px 0",
                              }}
                            ></div>
                          </div>
                        ) : null}
                      </Collapse>
                    </div>
                  );
                })}
            </section>
          </Grid>
          <Grid item style={{ flex: "2" }}>
            <MapContainer center={[43, 0]} zoom={5} style={{ height: "calc(100vh - 320px)", width: "60vw" }} anima>
              <TileLayer
                attribution={IS_ONLINE ? "Google Maps" : "OpenStreetMap"}
                url={MAP_TYPES.find((type) => type.value === mapType).tileLayer}
                maxZoom={16}
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
              />
              {centroid.length > 0 && <Marker key={"Centroid"} position={centroid} icon={customCentroidIcon}></Marker>}
              <MyComponentSetView polyline={polylineDisplay} />
              {polylineDisplay.length > 0 &&
                // eslint-disable-next-line array-callback-return
                polylineDisplay.map((polyline, index) => {
                  // eslint-disable-next-line array-callback-return
                  return polyline.points.map((point, indice) => {
                    if (indice === 0) {
                      return null;
                    }

                    if (polyline.p_indexes.includes(indice)) {
                      const previousIndex = polyline.p_indexes[polyline.p_indexes.indexOf(indice) - 1];
                      const center = [
                        [
                          polylineDisplay[index].points[previousIndex].lat,
                          polylineDisplay[index].points[previousIndex].lng,
                        ],
                        [point.lat, point.lng],
                      ];
                      const color = polylineColors[index];
                      return (
                        <Polyline key={indice + "_" + index} color={color} positions={center} weight={5}></Polyline>
                      );
                    }
                  });
                })}
              <AddEquipment />
              {equipment.length > 0 &&
                equipment.map((equip, index) => (
                  <Marker
                    key={"equipment" + index}
                    position={[equip.latitude, equip.longitude]}
                    icon={open[index] ? customShadowIcon : customIcon}
                  ></Marker>
                ))}
            </MapContainer>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};
export default EquipmentInfo;
