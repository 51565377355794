import React, { useEffect, useMemo, useRef, useState } from "react";
import { clone, isEmpty, isNil } from "ramda";
import { scaleLinear, interpolateRgb } from "d3";

import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap, useMapEvents } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { Autocomplete, Checkbox, MenuItem, TextField } from "@mui/material";

import palette from "../../../../../config/colorPalette";
import AlertPopup from "../AlertPopup";
import Legend from "../Leyend";
import MapTypeSelector from "../MapTypeSelector";
import {
  alarmsQuantity,
  asociarNumeroConLista,
  //getBoundaries,
  getCentroid,
  getLineColor,
  getPolylineAlertsMapMark,
  printEquipments,
  selectEquipment,
  selectEquipmentInAssetReceiving,
  shouldAddAccordingToMeasure,
  shouldAddAccordingToState,
} from "./utils/utils";

import { LegendContent, MapTypeContent } from "./styled";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { getAssetsValuesByAssets, getPointsByAssets } from "../../../../api";
import { setAssetsPointsData } from "../../../../redux/assetsPointsSlice";
import { setAssetsData } from "../../../../redux/assetsValuesSlice";
import { IS_ONLINE, MAP_TYPES } from "../../../../../config/configmap";
import { toast } from "react-toastify";

const customAlertIcon = new Icon({
  iconUrl: require("../../../../../images/Uptech-marker-alerts-icon.png"),
  iconSize: [50, 50],
  iconAnchor: [25, 50],
});

const customIcon = new Icon({
  iconUrl: require("../../../../../images/Uptech-marker-icon.png"),
  iconSize: [50, 50],
  iconAnchor: [25, 50],
});

const customEquipmentAlertIcon = new Icon({
  iconUrl: require("../../../../../images/Uptech-equipment-marker-alerts-icon.png"),
  iconSize: [50, 50],
});

const customEquipmentIcon = new Icon({
  iconUrl: require("../../../../../images/Uptech-equipment-marker-icon.png"),
  iconSize: [50, 50],
});

const Map = ({ assets, alerts, mapColors, borders, setBorders, objectMeasures, dispatch, intl }) => {
  const navigate = useNavigate();
  const [domain, setDomain] = useState([0, 0.55]);
  const [range, setRange] = useState([interpolateRgb("green")(0), interpolateRgb("red")(0.55)]);
  const [inside, setInside] = useState([]);
  const [visible, setVisible] = useState([]);
  const [points, setPoints] = useState({ data: [] });
  const [assetsValues, setAssetsValues] = useState({ data: [] });
  const [assetEquipments, setAssetEquipments] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [indexEquipmentChannel, setIndexEquipmentChannel] = useState(0);
  const [equipmentChannels, setEquipmentChannels] = useState([]);
  const [equipmentChannelNames, setEquipmentChannelNames] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const autocompleteRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const tColorScale = scaleLinear().domain(domain).range(range);
  const [hasRunOnce, setHasRunOnce] = useState(false);
  const [stateMagnitudes, setStateMagnitude] = useState([]);
  const differencesForTempLength = useState([
    { range: 5000, multiplier: 1 },
    { range: 15000, multiplier: 2 },
    { range: 25000, multiplier: 3 },
  ]);
  const [toastWarning, setToastWarning] = useState(true);

  const scales = useMemo(() => {
    const jetDomain = [];
    const jetRange = [];
    const seismicDomain = [];
    const seismicRange = [];
    if (!isNil(mapColors)) {
      for (let index = 0; index < mapColors["jet"]["blue"].length; index++) {
        jetDomain.push(mapColors["jet"]["value"][index]);
        const redJET = Math.round(mapColors["jet"]["red"][index] * 255);
        const greenJET = Math.round(mapColors["jet"]["green"][index] * 255);
        const blueJET = Math.round(mapColors["jet"]["blue"][index] * 255);
        const hexJET = rgbToHex(redJET, greenJET, blueJET);
        jetRange.push(interpolateRgb(hexJET)(mapColors["jet"]["value"][index]));
        seismicDomain.push(mapColors["seismic"]["value"][index]);
        const redSEISMIC = Math.round(mapColors["seismic"]["red"][index] * 255);
        const greenSEISMIC = Math.round(mapColors["seismic"]["green"][index] * 255);
        const blueSEISMIC = Math.round(mapColors["seismic"]["blue"][index] * 255);
        const hexSeismic = rgbToHex(redSEISMIC, greenSEISMIC, blueSEISMIC);
        seismicRange.push(interpolateRgb(hexSeismic)(mapColors["seismic"]["value"][index]));
      }
      //coger las medidas que tendran todos los asset y hacer un array de colores definitivo
      let result = {
        status: scaleLinear()
          .domain([0, 0.55])
          .range([interpolateRgb("green")(0), interpolateRgb("red")(0.55)]),
        "status.secondary": scaleLinear()
          .domain([0, 0.55])
          .range([interpolateRgb("green")(0), interpolateRgb("red")(0.55)]),
        leak: scaleLinear()
          .domain([0, 0.55])
          .range([interpolateRgb("green")(0), interpolateRgb("red")(0.55)]),
        temperature: scaleLinear().domain(jetDomain).range(jetRange),
        landslide: scaleLinear()
          .domain([0, 0.55])
          .range([interpolateRgb("green")(0), interpolateRgb("red")(0.55)]),
        strain: scaleLinear().domain(seismicDomain).range(seismicRange),
      };
      return result;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapColors]);

  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  const [selectedType, setSelectedType] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [currentAsset, setCurrentAsset] = useState(0);
  const [filter, setFilter] = useState("status");
  const [filtered_asset, setFiltered_Asset] = useState("");
  const [currentAssetValues, setCurrentAssetValues] = useState();
  // eslint-disable-next-line no-unused-vars
  const [measureMinus, setMeasureMinus] = useState(0);

  const bounds = useMemo(() => !isNil(assets) && assets.map((item) => item.centroid), [assets]);

  const assetsOptions = useMemo(() => {
    let result = [];
    if (!isNil(assets)) {
      if (assets.length > 1) {
        result = [intl.formatMessage({ id: "all" })];
      }
      result = [...result, ...assets.map((a) => a.name)];
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets]);
  const [currentAssetMeasure, setCurrentAssetMeasure] = useState([]);

  const polylines = useMemo(() => {
    if (!isNil(assets) && !isEmpty(assetsValues?.data) && !isNil(points?.data) && !isEmpty(points?.data)) {
      let bordersClone = clone(borders);
      let auxEquipmentIndex = -1;
      const result = assets.reduce((acc, asset, reduceIndex) => {
        let minLon = 0,
          maxLon = 0,
          minLat = 0,
          maxLat = 0;
        if (inside.includes(asset.name)) {
          for (let indexEquipment = 0; indexEquipment < asset.equipments.length; indexEquipment++) {
            auxEquipmentIndex++;
            const aValues = assetsValues.data[asset.name][asset.equipments[indexEquipment].equipmentID];
            const statusMeasures = []; // the first values for each measures for using them into the prevValues variable

            const equipment = asset.equipments[indexEquipment];
            const equipmentType = objectMeasures.find((item) => item.name === equipment.equipmentType);
            let measures = filter === "status" ? stateMagnitudes : equipment.pMeasureNames;
            for (let j = 0; j < measures.length; j++) {
              const measure = measures[j];
              if (!isNil(aValues?.[measure])) statusMeasures.push(aValues[measure]?.[0]); //
            }

            const umbrales = [];
            if (filter === "status" || filter === "status.secondary") {
              const notComputed = equipmentType.measures;
              const computed = equipmentType.computing;

              for (let index = 0; index < notComputed.length; index++) {
                if (measures.includes(notComputed[index])) {
                  const indexOF = equipment.pMeasureNames.indexOf(notComputed[index]);
                  umbrales.push([]);
                  const thisThreshold = equipment.pThresholds[indexOF];
                  const thisActive = equipment.pThresholdsActive[indexOF];
                  for (let indexActive = 0; indexActive < thisActive.length; indexActive++) {
                    if (thisActive[indexActive] === 1) {
                      umbrales[umbrales.length - 1].push(thisThreshold[indexActive]);
                    }
                  }
                }
              }

              for (let index = 0; index < computed.length; index++) {
                if (measures.includes(computed[index])) {
                  umbrales.push([]);
                  umbrales[umbrales.length - 1].push(0.5);
                }
              }
            } else {
              if (!equipmentType.computing.includes(filter)) {
                const measureIndex = selectedType - measureMinus;
                const thisThreshold = equipment.pThresholds[measureIndex];
                const thisActive = equipment.pThresholdsActive[measureIndex];
                for (let indexActive = 0; indexActive < thisActive.length; indexActive++) {
                  if (thisActive[indexActive] === 1) {
                    umbrales.push(thisThreshold[indexActive]);
                  }
                }
              } else {
                umbrales.push(0.5);
              }
            }

            const maxPercentiles = [];
            const minPercentiles = [];
            const equipmentAdditionalMeasures = [];
            if (
              isEmpty(selectedChannels[indexEquipment + inside.indexOf(asset.name)]) ||
              isNil(selectedChannels[indexEquipment + inside.indexOf(asset.name)])
            ) {
              for (let index = 0; index < Object.keys(aValues).length; index++) {
                const channelData = aValues[(index + 1).toString()];
                if (!isNil(channelData) && !isEmpty(channelData)) {
                  if (Object.keys(channelData.additional_measures).length > 0) {
                    equipmentAdditionalMeasures.push(Object.keys(channelData.additional_measures));
                  }
                  if (!isEmpty(channelData) && !isNil(channelData.boundaries[filter])) {
                    maxPercentiles.push(channelData.boundaries[filter][1]);
                    minPercentiles.push(channelData.boundaries[filter][0]);
                  }
                }
              }
            } else {
              for (
                let index = 0;
                index < selectedChannels[indexEquipment + inside.indexOf(asset.name)].length;
                index++
              ) {
                const id = (
                  parseInt(selectedChannels[indexEquipment + inside.indexOf(asset.name)][index]) + 1
                ).toString();
                const channelData = aValues[id];
                if (!isNil(channelData) && !isEmpty(channelData)) {
                  if (Object.keys(channelData.additional_measures).length > 0) {
                    equipmentAdditionalMeasures.push(Object.keys(channelData.additional_measures));
                  }
                  if (!isEmpty(channelData) && !isNil(channelData.boundaries[filter])) {
                    maxPercentiles.push(channelData.boundaries[filter][1]);
                    minPercentiles.push(channelData.boundaries[filter][0]);
                  }
                }
                if (isNil(channelData) || isEmpty(channelData)) {
                  if (toastWarning) {
                    toast.warning(`${intl.formatMessage({ id: "the.channel.has.no.data" })}`, {
                      className: "toast-warning",
                    });
                    setToastWarning(false);
                  }
                }
              }
            }

            const max =
              Math.max(...maxPercentiles) === Infinity || Math.max(...maxPercentiles) === -Infinity
                ? 1
                : Math.max(...maxPercentiles);
            const min =
              Math.min(...minPercentiles) === -Infinity || Math.min(...minPercentiles) === Infinity
                ? 0
                : Math.min(...minPercentiles);
            for (
              let indexChannels = 0;
              indexChannels < equipmentChannels[indexEquipment + inside.indexOf(asset.name)].length;
              indexChannels++
            ) {
              let prevValues = {
                state: null,
                values:
                  filter === "status" || filter === "status.secondary"
                    ? statusMeasures
                    : !isNil(aValues)
                    ? aValues[(indexChannels + 1).toString()][equipment.pMeasureNames[selectedType - measureMinus]]?.[0]
                    : null,
              }; // has the measure values for the previous visited point
              const positionsRepeated = [];
              for (let i = 0; i < equipmentChannels[indexEquipment + inside.indexOf(asset.name)].length; i++) {
                if (
                  equipmentChannels[indexEquipment + inside.indexOf(asset.name)][i] ===
                  equipmentChannels[indexEquipment + inside.indexOf(asset.name)][indexChannels]
                ) {
                  positionsRepeated.push(i + acc.length - indexChannels);
                }
              }
              const filteredPoints = []; // the list of points to be printed
              if (
                !isNil(selectedChannels[indexEquipment + inside.indexOf(asset.name)]) &&
                selectedChannels[indexEquipment + inside.indexOf(asset.name)].includes(indexChannels)
              ) {
                let mult = 4;
                const count =
                  points.data?.[
                    `${asset.name}${equipmentChannels[indexEquipment + inside.indexOf(asset.name)][indexChannels] + 1}`
                  ].points.length;
                for (let indexDiff = 0; indexDiff < differencesForTempLength[0].length; indexDiff++) {
                  if (count < differencesForTempLength[0][indexDiff].range) {
                    mult = differencesForTempLength[0][indexDiff].multiplier;
                  }
                }
                for (let i = 0; i < count; i++) {
                  let p =
                    points.data?.[
                      `${asset.name}${
                        equipmentChannels[indexEquipment + inside.indexOf(asset.name)][indexChannels] + 1
                      }`
                    ].points[i];
                  if (
                    !isNil(p) &&
                    !isNil(aValues[(indexChannels + 1).toString()]) &&
                    !isEmpty(aValues[(indexChannels + 1).toString()])
                  ) {
                    const channelValues = aValues[(indexChannels + 1).toString()];
                    const additional = !isNil(equipmentAdditionalMeasures[indexChannels])
                      ? equipmentAdditionalMeasures[indexChannels].includes(filter)
                      : null;
                    const additionalValues = additional ? channelValues.additional_measures[filter] : null;
                    const valores = [];
                    if (filter === "status" || filter === "status.secondary") {
                      for (let measuresIndex = 0; measuresIndex < measures.length; measuresIndex++) {
                        if (!equipmentType.computing.includes(measures[measuresIndex])) {
                          valores.push(channelValues[measures[measuresIndex]][i]);
                        }
                      }
                    }
                    const differenceMultiplier = filter === "temperature" ? 1 * mult : 6;
                    const shouldAdd =
                      filter === "status" || filter === "status.secondary"
                        ? shouldAddAccordingToState({
                            indexes:
                              points.data?.[
                                `${asset.name}${
                                  equipmentChannels[indexEquipment + inside.indexOf(asset.name)][indexChannels] + 1
                                }`
                              ].p_indexes,
                            index: i,
                            thresholds: umbrales,
                            values: valores,
                            prevValues,
                            filteredPoints,
                            measures,
                          })
                        : shouldAddAccordingToMeasure({
                            indexes:
                              points.data?.[
                                `${asset.name}${
                                  equipmentChannels[indexEquipment + inside.indexOf(asset.name)][indexChannels] + 1
                                }`
                              ].p_indexes,
                            index: i,
                            thresholds: umbrales,
                            difference: channelValues.differences[filter] * differenceMultiplier,
                            value: !isNil(channelValues[equipment.pMeasureNames[selectedType - measureMinus]]?.[i])
                              ? isNil(additionalValues)
                                ? channelValues[equipment.pMeasureNames[selectedType - measureMinus]][i]
                                : null
                              : 0,
                            prevValues,
                            filteredPoints,
                          });
                    if (shouldAdd.should) {
                      filteredPoints.push({
                        ...p,
                        index: i,
                        alertType: prevValues.state,
                        values:
                          filter === "status" || filter === "status.secondary"
                            ? !isNil(shouldAdd.measuresValue)
                              ? shouldAdd.measuresValue
                              : shouldAdd.values
                            : shouldAdd.measureValue,
                        measureIndex: selectedType - measureMinus,
                      });
                    }
                  }
                }
                if (!isEmpty(filteredPoints)) {
                  const pList = []; // polylines list
                  for (let i = 0; i < filteredPoints.length - 1; i++) {
                    const p1 = filteredPoints[i];
                    const p2 = filteredPoints[i + 1];
                    if (indexEquipment === 0 && indexChannels === 0 && i === 0) {
                      maxLat = p1.latitude;
                      minLat = p1.latitude;
                      maxLon = p1.longitude;
                      minLon = p1.latitude;
                    } else {
                      if (minLat > p1.latitude) {
                        minLat = p1.latitude;
                      } else if (maxLat < p1.latitude) {
                        maxLat = p1.latitude;
                      }
                      if (minLon > p1.longitude) {
                        minLon = p1.longitude;
                      } else if (maxLon < p1.longitude) {
                        maxLon = p1.longitude;
                      }
                    }

                    const color = getLineColor({
                      filter,
                      asset,
                      equipment,
                      data: aValues[(indexChannels + 1).toString()],
                      max,
                      min,
                      p: p1,
                      assetIndex: reduceIndex,
                      tColorScale,
                      umbrales,
                      //minBoundaries,
                      //maxBoundaries,
                    });

                    pList.push({ p1, p2, alertType: p1.alertType, color });
                  }

                  const lastPoint = filteredPoints[filteredPoints.length - 1];
                  const color = getLineColor({
                    filter,
                    asset,
                    equipment,
                    data: aValues[(indexChannels + 1).toString()],
                    max,
                    min,
                    p: lastPoint,
                    assetIndex: reduceIndex,
                    tColorScale,
                    umbrales,
                    //minBoundaries,
                    //maxBoundaries,
                  });
                  const selChan = [];
                  for (
                    let indexSelChan = 0;
                    indexSelChan < selectedChannels[indexEquipment + inside.indexOf(asset.name)].length;
                    indexSelChan++
                  ) {
                    selChan.push(
                      selectedChannels[indexEquipment + inside.indexOf(asset.name)][indexSelChan] +
                        acc.length -
                        indexChannels,
                    );
                  }
                  pList.push({
                    p1: lastPoint,
                    p2: lastPoint,
                    alertType: lastPoint.alertType,
                    color,
                    equal: positionsRepeated,
                    asset: asset.name,
                    selectedChannels: selChan,
                    equipmentIndex: auxEquipmentIndex,
                  });
                  acc.push(pList);
                }
              }
            }
          }
          const deltaLat = (maxLat - minLat) / 100;
          const deltaLon = (maxLon - minLon) / 100;
          bordersClone[asset.name] = [
            [minLat, minLon],
            [maxLat, maxLon],
            [(minLat + maxLat) / 2, (minLon + maxLon) / 2],
          ];
          for (let borderIndex = 0; borderIndex < 100; borderIndex++) {
            bordersClone[asset.name].push([minLat + borderIndex * deltaLat, minLon + borderIndex * deltaLon]);
          }
          setLoading(false);
          return acc;
        } else {
          return acc;
        }
      }, []);
      setBorders(bordersClone);
      return result;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets, assetsValues, points, filter, selectedChannels, visible]);

  const mapRef = useRef(null);
  const [maxBounds, setMaxBounds] = useState(null);
  const [originalBounds, setOriginalBounds] = useState(null);
  const zoom = useMemo(() => {
    let result = 8;
    if (!isNil(assets)) {
      if (assets.length === 1) {
        result = assets[0]?.zoom;
      } else if (!isNil(bounds)) {
        result = 5;
      }
    }
    return result;
  }, [assets, bounds]);

  const [mapCentroid, setMapCentroid] = useState();

  const [mapType, setMapType] = useState("regular");
  const [currentZoom, setCurrentZoom] = useState(zoom);
  const [assetZoom, setAssetZoom] = useState(zoom);

  const [reset, setReset] = useState(true);
  useEffect(() => {
    let effectZoom = assets.length === 1 ? assets[0]?.zoom - 2 : 8;
    if (mapRef.current && assetZoom <= effectZoom) {
      const currentBounds = mapRef.current.getBounds();
      const southWest = currentBounds.getSouthWest(); // Devuelve un LatLng
      const northEast = currentBounds.getNorthEast(); // Devuelve un LatLng
      setMaxBounds([
        [southWest.lat, southWest.lng],
        [northEast.lat + 0.5, northEast.lng],
      ]);
      setOriginalBounds([
        [southWest.lat, southWest.lng],
        [northEast.lat + 0.5, northEast.lng],
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef.current]);

  useEffect(() => {
    let result = null;
    if (!isNil(assets)) {
      result = getCentroid(assets.map((item) => item.centroid));
    }
    setMapCentroid(result);
  }, [assets]);

  function ChangeView() {
    const mapView = useMap();
    useEffect(() => {
      if (loading) {
        mapView.dragging.disable();
        mapView.scrollWheelZoom.disable();
        mapView.doubleClickZoom.disable();
        mapView.boxZoom.disable();
        mapView.keyboard.disable();
      } else {
        mapView.dragging.enable();
        mapView.scrollWheelZoom.enable();
        mapView.doubleClickZoom.enable();
        mapView.boxZoom.enable();
        mapView.keyboard.enable();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, mapView]);
    useMapEvents({
      zoomend: async (event) => {
        assetDataReceiving(event, mapView);
      },
      dragend: async (event) => {
        assetDataReceiving(event, mapView);
      },
    });

    useEffect(() => {
      if (!hasRunOnce && assets.length === 1) {
        setTimeout(() => {
          setCurrentZoom(assets[0].zoom);
        }, 0);
        const initialEvent = {
          type: "zoomend",
          target: mapView,
          sourceTarget: mapView,
          propagatedFrom: null,
        };
        assetDataReceiving(initialEvent, mapView);

        setHasRunOnce(true);
      }
    }, [mapView]);

    if (!isNil(mapCentroid) && reset) {
      mapView.setMaxBounds(null);
      mapView.setView(mapCentroid, currentZoom, { reset: false });
    }
    if (maxBounds && !reset) {
      mapView.setMaxBounds(maxBounds);
    }
    setReset(false);

    return null;
  }
  function puntoDentroRectangulo(x1, y1, x2, y2, xc, yc) {
    if (xc === 0 && yc === 0) {
      return false;
    }
    if (xc >= x1 && xc <= x2 && yc >= y1 && yc <= y2) {
      return true;
    } else {
      return false;
    }
  }

  async function assetDataReceiving(event, mapView) {
    const pointsZoom = {};
    const assetsValuesZoom = {};
    const insideZoom = [];
    const visibleZoom = [];
    const equipmentsInside = [];
    const channelsInside = [];
    const channelNamesInside = [];
    let selectedChannelsZoom = [];
    const currentMeasuresIN = [];
    let isFirstTime = true;
    const targetZoom = event.target.getZoom();
    setCurrentZoom(targetZoom);
    for (let index = 0; index < assets.length; index++) {
      const name = assets[index].name;
      const centroid = [assets[index].centroid[0], assets[index].centroid[1]];
      const bounds = mapView.getBounds();
      const centro = { lat: centroid[0], lng: centroid[1] };
      let estaEnLinea = false;
      for (let borderIndex = 0; borderIndex < 103; borderIndex++) {
        if (!isNil(borders[name][borderIndex])) {
          if (bounds.contains({ lat: borders[name][borderIndex][0], lng: borders[name][borderIndex][1] })) {
            estaEnLinea = true;
          }
        }
      }
      if (inside.includes(name)) {
        estaEnLinea = puntoDentroRectangulo(
          borders[name][0][0],
          borders[name][0][1],
          borders[name][1][0],
          borders[name][1][1],
          borders[name][2][0],
          borders[name][2][1],
        );
      }
      const isDentro = bounds.contains(centro) || estaEnLinea;
      if (isDentro && targetZoom === 10) {
        const southWest = bounds.getSouthWest(); // Devuelve un LatLng
        const northEast = bounds.getNorthEast(); // Devuelve un LatLng
        setMaxBounds([
          [southWest.lat, southWest.lng],
          [northEast.lat, northEast.lng],
        ]);
        setMaxBounds(originalBounds);
        setPoints({ data: [] });
        setAssetsValues({ data: [] });
        setInside([]);
        setVisible([]);
        setAssetEquipments([]);
        setSelectedEquipment(null);
        setIndexEquipmentChannel(0);
        setEquipmentChannels([]);
        setEquipmentChannelNames([]);
        setSelectedChannels([]);
        setToastWarning(true);
      } else if (targetZoom < 10) {
        //setReset(false);
        setMaxBounds(originalBounds);
        setPoints({ data: [] });
        setAssetsValues({ data: [] });
        setInside([]);
        setVisible([]);
        setAssetEquipments([]);
        setSelectedEquipment(null);
        setIndexEquipmentChannel(0);
        setEquipmentChannels([]);
        setEquipmentChannelNames([]);
        setSelectedChannels([]);
        setToastWarning(true);
      }
      if (isDentro && targetZoom >= 11) {
        setMapCentroid(assets[index].centroid);
        insideZoom.push(name);
        if (inside.includes(name)) {
          const indexVisible = inside.indexOf(name);
          for (let indexEquipment = 0; indexEquipment < assets[index].equipments.length; indexEquipment++) {
            visibleZoom.push(visible[indexVisible]);
          }
          setToastWarning(false);
        } else {
          for (let indexEquipment = 0; indexEquipment < assets[index].equipments.length; indexEquipment++) {
            visibleZoom.push(true);
          }
          setToastWarning(true);
        }
        equipmentsInside.push(assets[index].equipments);
        for (let indexEquipment = 0; indexEquipment < assets[index].equipments.length; indexEquipment++) {
          channelsInside.push(assets[index].equipments[indexEquipment].channels);
          channelNamesInside.push(assets[index].equipments[indexEquipment].channelsNames);
          const auxChan = [];
          for (let indexAux = 0; indexAux < assets[index].equipments[indexEquipment].channels.length; indexAux++) {
            auxChan.push(indexAux);
          }
          selectedChannelsZoom.push(auxChan);
          let auxMeasures = assets[index].equipments[indexEquipment].pMeasureNames;
          auxMeasures = auxMeasures.filter((cmi) => cmi !== "depth_of_burial");
          currentMeasuresIN.push(auxMeasures);
        }
        if (isNil(assetsValues.data[name])) {
          if (isFirstTime) {
            //setFilter(assets[index].equipments[0].pMeasureNames[0]);
            //setCurrentAsset(index + 1);
            setFiltered_Asset(assets[index].name);
            setAssetZoom(assets[index].zoom);
            isFirstTime = false;
          }
          //setReset(true);
          setLoading(true);
          const response = await getPointsByAssets(name);
          for (let index = 0; index < response.data.data.length; index++) {
            pointsZoom[`${name}${index + 1}`] = response.data.data[index];
          }
          dispatch(setAssetsPointsData(response.data));
          const response2 = await getAssetsValuesByAssets(name);
          assetsValuesZoom[name] = response2.data.data;
          setCurrentAssetValues(response2.data.data);
          dispatch(setAssetsData(response2.data));
        } else {
          let cont = 0;
          let realIndex = [];
          const allEquipments = [];
          for (let indexAsset = 0; indexAsset < assetEquipments.length; indexAsset++) {
            for (let indexAssetEquip = 0; indexAssetEquip < assetEquipments[indexAsset].length; indexAssetEquip++) {
              allEquipments.push(assetEquipments[indexAsset][indexAssetEquip]);
              if (assetEquipments[indexAsset][indexAssetEquip].asset === name) {
                realIndex.push(cont);
              }
              cont++;
            }
          }
          cont = 0;
          for (let indexInside = 0; indexInside < equipmentsInside.length; indexInside++) {
            if (equipmentsInside.length - 1 > indexInside) {
              cont += equipmentsInside[indexInside].length; //para saber cuantos equipos hay de antes
            }
          }
          for (let indexAsset = 0; indexAsset < realIndex.length; indexAsset++) {
            selectedChannelsZoom[cont + indexAsset] = selectedChannels[realIndex[indexAsset]];
          }
          const keysWithAlreadyExistingAsset = Object.keys(points.data).filter((key) => key.startsWith(name));
          // eslint-disable-next-line no-unused-vars
          const objetoConAlreadyExistingAsset = keysWithAlreadyExistingAsset.reduce((obj, key) => {
            obj[key] = points.data[key];
            pointsZoom[key] = points.data[key];
            return obj;
          }, {});
          assetsValuesZoom[name] = assetsValues.data[name];
        }
      }
    }
    if (!isEmpty(currentMeasuresIN)) {
      const currentMeasuresOUT = [];
      const measuresForStatus = [];
      if (equipmentsInside[0]?.[0].pMeasureAlerts.includes(1)) {
        const count = equipmentsInside[0]?.[0].pMeasureAlerts.filter((alert) => alert === 1).length;
        const notStatus = equipmentsInside[0]?.[0].pMeasureNames.includes("depth_of_burial") && count === 1;
        if (!notStatus) {
          setMeasureMinus(1);
          currentMeasuresOUT.push("status");
          for (let indexStatus = 0; indexStatus < equipmentsInside[0]?.[0].pMeasureAlerts.length; indexStatus++) {
            if (
              equipmentsInside[0]?.[0].pMeasureAlerts[indexStatus] === 1 &&
              equipmentsInside[0]?.[0].pMeasureNames[indexStatus] !== "depth_of_burial"
            ) {
              measuresForStatus.push(equipmentsInside[0]?.[0].pMeasureNames[indexStatus]);
            }
          }
        } else {
          setMeasureMinus(0);
        }

        setStateMagnitude(measuresForStatus);
      } else {
        setMeasureMinus(0);
        setStateMagnitude([]);
      }
      //const currentMeasuresOUT = ["status", "status.secondary"];
      for (var i = 0; i < currentMeasuresIN.length; i++) {
        var subArray = currentMeasuresIN[i];
        for (var j = 0; j < subArray.length; j++) {
          var elemento = subArray[j];
          if (currentMeasuresOUT.indexOf(elemento) === -1) {
            currentMeasuresOUT.push(elemento);
          }
        }
      }
      setCurrentAssetMeasure(currentMeasuresOUT);
      if (currentMeasuresOUT.length > 0) {
        if (currentMeasuresOUT.includes(filter)) {
          setRange(scales[filter].range());
          setDomain(scales[filter].domain());
        } else {
          setFilter(currentMeasuresOUT[0]);
          setRange(scales[currentMeasuresOUT[0]].range());
          setDomain(scales[currentMeasuresOUT[0]].domain());
        }
      }
    }
    if (isFirstTime) setFiltered_Asset(insideZoom[0]);
    if (!isEmpty(currentMeasuresIN)) setCurrentAssetValues(assetsValuesZoom[assets[0].name]);
    if (!isEmpty(insideZoom)) setInside(insideZoom);
    if (!isEmpty(visibleZoom)) setVisible(visibleZoom);
    let foundItem = null;
    if (!isEmpty(equipmentsInside)) {
      foundItem = equipmentsInside[0].find((item) => item.equipmentID === selectedEquipment);
    }

    if (!isEmpty(equipmentsInside) && isNil(foundItem) && isNil(selectedEquipment)) {
      setSelectedEquipment(equipmentsInside[0]?.[0].equipmentID);
    } else if (!isEmpty(equipmentsInside) && isNil(foundItem) && !isNil(selectedEquipment)) {
      selectEquipmentInAssetReceiving(
        selectedEquipment,
        equipmentsInside,
        setFiltered_Asset,
        setMeasureMinus,
        setStateMagnitude,
        setSelectedEquipment,
        setIndexEquipmentChannel,
        setSelectedChannels,
        autocompleteRef,
      );
    }
    setSelectedChannels(selectedChannelsZoom);

    //setIndexEquipmentChannel(0);
    if (!isEmpty(equipmentsInside)) setAssetEquipments(equipmentsInside);
    if (!isEmpty(channelsInside)) setEquipmentChannels(channelsInside);
    if (!isEmpty(channelNamesInside)) setEquipmentChannelNames(channelNamesInside);
    if (!isEmpty(pointsZoom)) setPoints((prevPoints) => ({ ...prevPoints, data: pointsZoom }));
    if (!isEmpty(assetsValuesZoom)) setAssetsValues((prevValues) => ({ ...prevValues, data: assetsValuesZoom }));
  }
  function generateRandomString(length) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  return (
    mapCentroid && (
      <MapContainer center={mapCentroid} bounds={bounds} maxBounds={maxBounds} ref={mapRef} maxBoundsViscosity={1}>
        <TileLayer
          attribution={IS_ONLINE ? "Google Maps" : "OpenStreetMap"}
          url={MAP_TYPES.find((type) => type.value === mapType).tileLayer}
          maxZoom={16}
          bounds={maxBounds}
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
        <ChangeView />
        {!isNil(currentZoom) &&
          !isNil(assets) &&
          (currentZoom >= 11
            ? !isNil(polylines) &&
              assets.map((asset, assetIndex) => (
                <div key={`${asset}-${assetIndex}`}>
                  {(filter === "status" || filter === "status.secondary" || inside.includes(asset.name)) &&
                    !isNil(polylines?.[inside.indexOf(asset.name)]) &&
                    // eslint-disable-next-line array-callback-return
                    polylines.map((polyline, plineIndex) => {
                      const lista = [0.003525 / 4, 0.003 / 4, 0.002475 / 4, 0.00195 / 4, 0.00145 / 4, 0.0009 / 4];
                      const equipmentIndex = polyline[polyline.length - 1].equipmentIndex;
                      if (visible[equipmentIndex]) {
                        const asociado = asociarNumeroConLista(currentZoom, lista);
                        const repeated = polyline[polyline.length - 1].equal;
                        const realIndex = plineIndex - Math.min(...repeated);
                        const selChan = polyline[polyline.length - 1].selectedChannels;
                        const length = repeated.length;
                        if (repeated.includes(plineIndex) && length > 1) {
                          if (isEmpty(selChan) || selChan.includes(plineIndex)) {
                            const half = Math.round(length / 2);
                            const m = realIndex + 1 > half ? 1 : -1;
                            const extra =
                              length % 2 === 0 ? Math.round((realIndex + 1) / half) : Math.round(realIndex / half);
                            const distance = asociado * m * extra;
                            const pointIni = [polyline[0].p1.latitude, polyline[0].p1.longitude];
                            const pointEnd = [
                              polyline[polyline.length - 1].p2.latitude,
                              polyline[polyline.length - 1].p2.longitude,
                            ];
                            const atanV1 = Math.atan2(pointEnd[0] - pointIni[0], pointEnd[1] - pointIni[1]);
                            const offset_v1_latitude = -distance * Math.cos(atanV1);
                            const offset_v1_longitude = distance * Math.sin(atanV1);
                            return polyline.map((line, lineIndex) => (
                              <Polyline
                                key={`${filter}-${asset.name}-${lineIndex}-${plineIndex}-${generateRandomString(50)}`}
                                positions={[
                                  [line.p1.latitude + offset_v1_latitude, line.p1.longitude + offset_v1_longitude],
                                  [line.p2.latitude + offset_v1_latitude, line.p2.longitude + offset_v1_longitude],
                                ]}
                                weight={6}
                                color={line.color}
                              ></Polyline>
                            ));
                          }
                        } else {
                          return polyline.map((line, lineIndex) => (
                            <Polyline
                              key={`${filter}-${asset.name}-${lineIndex}-${plineIndex}-${generateRandomString(50)}`}
                              positions={[
                                [line.p1.latitude, line.p1.longitude],
                                [line.p2.latitude, line.p2.longitude],
                              ]}
                              weight={6}
                              color={line.color}
                            ></Polyline>
                          ));
                        }
                      }
                    })}
                </div>
              ))
            : assets.map((asset) => {
                const alarmCount = alarmsQuantity(alerts?.filter((a) => a.asset === asset.name));
                const equipments = asset.equipments;
                const notAlive = equipments.some((objeto) => !objeto.alive);
                const markerIcon = alarmCount.some((count) => count > 0) || notAlive ? customAlertIcon : customIcon;
                return (
                  <Marker key={asset.name} position={asset.centroid} icon={markerIcon}>
                    <Popup>
                      <AlertPopup
                        alarmCount={alarmCount}
                        alarmLabel={intl.formatMessage({ id: "structure" })}
                        alarmName={asset.name}
                        name={asset.name}
                        image={asset.image}
                        equipments={equipments}
                      />
                    </Popup>
                  </Marker>
                );
              }))}

        {loading && isEmpty(polylines) && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(176 176 176 / 60%)",
              zIndex: 10000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <h1>{`${intl.formatMessage({ id: "loading.points" })}...`}</h1>
          </div>
        )}

        {!isNil(currentZoom) &&
          !isEmpty(polylines) &&
          (currentZoom >= 11
            ? getPolylineAlertsMapMark({
                filter,
                alerts,
                points,
                filtered_asset,
                assets,
                polylines,
                currentAssetMeasure: currentAssetMeasure[selectedType],
                selectedEquipment,
                currentZoom,
                customIcon,
                objectMeasures,
                stateMagnitudes,
                intl,
                navigate,
              })
            : null)}
        {!isNil(currentZoom) &&
          (currentZoom >= 11
            ? !isNil(assets) &&
              assets
                .filter(
                  (a) =>
                    !isNil(a.equipments) &&
                    (filter === "status" || filter === "status.secondary" || inside.includes(a.name)),
                )
                ?.map((a, aIndex) => {
                  return printEquipments(
                    `${a.name}-equipment-${aIndex}"`,
                    a.equipments,
                    customEquipmentIcon,
                    customEquipmentAlertIcon,
                    intl,
                  );
                })
            : null)}

        {!isNil(assetsOptions) && currentZoom >= 11 && (
          <LegendContent>
            <TextField
              id="status"
              margin="dense"
              style={{ width: "10.5rem", margin: "2%" }}
              variant="outlined"
              value={selectedType}
              onChange={(e) => {
                const selection = e.target.value;
                const measure = currentAssetMeasure[selection];
                setCurrentAssetValues(assetsValues.data[measure]);

                setRange(scales[measure].range());
                setDomain(scales[measure].domain());
                setSelectedType(selection);
                setFilter(measure);
                if (measure === "status" || measure === "status.secondary") {
                  setMapCentroid(getCentroid(assets?.map((item) => item.centroid)));
                  setFiltered_Asset("");
                } else {
                  if (filtered_asset === "") {
                    setFiltered_Asset(assets?.[0]?.name);
                    setMapCentroid(assets?.[0]?.centroid);
                  }
                }
                setReset(true);
              }}
              select
            >
              {currentAssetMeasure.map((mg, i) => (
                <MenuItem key={`${mg}_current_select_${i}`} value={i}>
                  {`${intl.formatMessage({ id: mg })}`}
                </MenuItem>
              ))}
            </TextField>
            {!isNil(selectedEquipment) && !isNil(assetEquipments) && (
              <TextField
                id="status"
                margin="dense"
                style={{ width: "10.5rem", margin: "2%" }}
                variant="outlined"
                value={selectedEquipment}
                defaultValue={selectedEquipment}
                onChange={(e) => {
                  selectEquipment(
                    e.target.value,
                    assetEquipments,
                    setFiltered_Asset,
                    setMeasureMinus,
                    setStateMagnitude,
                    setSelectedEquipment,
                    setIndexEquipmentChannel,
                    setSelectedChannels,
                    autocompleteRef,
                  );
                }}
                select
              >
                {assetEquipments.reduce(
                  (acc, ae) => [
                    ...acc,
                    ...ae.map((equipment, innerIndex) => {
                      const globalIndex = acc.length + innerIndex;
                      return (
                        <MenuItem
                          disabled={!visible[globalIndex]}
                          key={`${equipment.equipmentID}_current_select_${globalIndex}`}
                          value={equipment.equipmentID}
                        >
                          {equipment.name}
                        </MenuItem>
                      );
                    }),
                  ],
                  [],
                )}
              </TextField>
            )}

            {!isNil(equipmentChannels[indexEquipmentChannel]) &&
              equipmentChannels[indexEquipmentChannel].length > 1 && (
                <Autocomplete
                  multiple
                  id="multiple-checkboxes"
                  options={[...equipmentChannels[indexEquipmentChannel].map((_, index) => index + 1)]}
                  disableCloseOnSelect
                  onChange={(event, values) => {
                    let newSelected = clone(selectedChannels);
                    if (event.isTrusted) {
                      const updatedValues = values.map((value) => value - 1);
                      newSelected[indexEquipmentChannel] = updatedValues;
                    }
                    setSelectedChannels(newSelected);
                  }}
                  getOptionLabel={(option) => equipmentChannelNames[indexEquipmentChannel][option - 1]}
                  value={
                    selectedChannels[indexEquipmentChannel] && selectedChannels[indexEquipmentChannel].length > 1
                      ? [...selectedChannels[indexEquipmentChannel].map((channel) => channel + 1)]
                      : []
                  }
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={
                            selectedChannels[indexEquipmentChannel] &&
                            selectedChannels[indexEquipmentChannel].length > 0
                              ? selectedChannels[indexEquipmentChannel].includes(option - 1)
                              : false
                          }
                        />
                        {equipmentChannelNames[indexEquipmentChannel][option - 1]}
                      </li>
                    );
                  }}
                  style={{ width: "10.5rem", margin: "2%" }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={intl.formatMessage({ id: "select.channels" })} />
                  )}
                  ref={autocompleteRef}
                />
              )}
            <Legend
              minValue={0}
              maxValue={1}
              assets={assets}
              scales={scales}
              filtered_asset={filtered_asset}
              measure_filter={filter}
              currentAssetValues={currentAssetValues}
              intl={intl}
              assetsValues={assetsValues}
              selectedChannels={selectedChannels}
              selectedEquipment={selectedEquipment}
              assetEquipments={assetEquipments}
              visible={visible}
              inside={inside}
            />
          </LegendContent>
        )}
        {IS_ONLINE ? (
          <MapTypeContent>
            <MapTypeSelector items={MAP_TYPES} onChange={setMapType} color={palette.dark} />
          </MapTypeContent>
        ) : null}
      </MapContainer>
    )
  );
};

export default Map;
